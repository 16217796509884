import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import transparentRectangleIcon from '@/assets/images/maps/regionBorder.png'
import mapsIcons from '@/components/icons/maps'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
  UNKNOWN,
  NOT_VERIFIED,
  VERIFIED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED,
} from './objectStatuses'
import {
  COUNTRY,
  REGION,
  CITY,
  GEOZONE,
  ROOT,
  INTEGRATION,
  PROJECT,
  GROUP,
  HEATING_DEVICE,
  COLD_WATER_DEVICE,
  TRASH,
  TRAFFIC,
  LIGHT,
  GROUP_OBJECT,
  OUTDOOR_LIGHTING,
  WATER_SUPPLY,
  HEAT_SUPPLY,
  GARBAGE_COLLECTION,
  CHARGING_STATIONS,
  HEAT_AND_WATTER_SUPPLY,
} from './objectTypes'
import {
  CONTROLLER,
  BRANCH_CIRCUIT,
  LAMP,
  PHASE,
  FUSE,
  CURRENT_SENSOR,
  PHASE_CIRCUIT_BREAKER,
  CONTACTOR,
  FIRE_SENSOR,
  LIGHT_FIXTURE,
  BASE_STATION,
  STREETLIGHT,
  UTILITY_POLE,
  STREETLIGHT_GROUP,
  CENTRAL_HEATING_DEVICE,
  STREETLIGHT_CONTROL_CABINET,
  DMX_DEVICE,
  LIGHT_SENSOR,
  LINE,
  ELECTRIC_METER,
  VOLUME_SENSOR,
  DOOR_OPEN_SENSOR,
  CONTROLLER_FUSE,
  SMOKE_SENSOR,
  CONTROL_CUPBOARD,
  ROAD_TEMPERATURE_SENSOR,
  MODULE,
  TRANSFER_SWITCH,
  PHOTO_SENSOR,
  MULTIRATE_METER,
  WASTE_CONTAINER,
  CHECKPOINT,
  METEO,
  LAMP_MODULE,
  SWITCHING_GROUP,
  OBJECTS,
  CONNECTED_LINES,
  MESH,
  SENSOR,
  COMPANY_OBJECT,
  DEVICE,
  KULON,
  BRIZ,
  ONE_SIM,
  WITHOUT_ASU,
  UNILIGHT,
  RUSSIA_BORDERS,
} from '@/constants/instalationPassport/types'

export const SYCN = 'syncToggle'

export const REGIONS_TYPES = [COUNTRY, REGION, CITY]
export const NOT_ELEMENTS_TYPES = [
  ...REGIONS_TYPES,
  CONTROLLER,
  HEATING_DEVICE,
  CENTRAL_HEATING_DEVICE,
  COLD_WATER_DEVICE,
]

export const CLUSTER_ANGLE_MARGIN = {
  zero: 0,
  small: 2,
  medium: 3,
  big: 5,
}

export const TITLE_TO_STATUS = {
  [OK]: <Lang id="mapsPage.titlesByStatus.ok" />,
  'OK': <Lang id="mapsPage.titlesByStatus.ok" />,
  [WARNING]: <Lang id="mapsPage.titlesByStatus.warning" />,
  [INFO]: <Lang id="mapsPage.titlesByStatus.info" />,
  [ERROR]: <Lang id="mapsPage.titlesByStatus.error" />,
  null: '-',
  [NOT_VERIFIED]: <Lang id="mapsPage.titlesByStatus.notVerified" />,
  [VERIFIED]: <Lang id="mapsPage.titlesByStatus.verified" />,
  [NEW]: <Lang id="mapsPage.titlesByStatus.statusNew" />,
  [NOT_EXIST]: <Lang id="mapsPage.titlesByStatus.notExist" />,
  [NOT_INSTALLED]: <Lang id="mapsPage.titlesByStatus.notInstalled" />,
  [INSTALLED]: <Lang id="mapsPage.titlesByStatus.installed" />,
  [UNINSTALLED]: <Lang id="mapsPage.titlesByStatus.unInstalled" />,
}

export const TITLE_TO_TOOLTIP_STATUS = {
  [OK]: 'Исправно',
  'OK': 'Исправно',
  [WARNING]: 'Возможны проблемы',
  [INFO]: 'Неактивно',
  [ERROR]: 'Проблемы в работе',
  null: '-',
  [NOT_VERIFIED]: 'Не проверен',
  [VERIFIED]: 'Проверен',
  [NEW]: 'Новый',
  [NOT_EXIST]: 'Не существует',
  [NOT_INSTALLED]: 'Не установлен',
  [INSTALLED]: 'Установлен',
  [UNINSTALLED]: 'Демонтирован',
}

export const REVERSE_ICON_TO_TYPE = {
  [OK]: mapsIcons.ReverseOkIcon,
  [WARNING]: mapsIcons.ReverseExclamationIcon,
  [INFO]: mapsIcons.ReverseQuestionIcon,
  [ERROR]: mapsIcons.ReverseCrossIcon,
  [UNKNOWN]: mapsIcons.ReverseCrossIcon,
  [NOT_VERIFIED]: mapsIcons.ReverseCrossIcon,
  [VERIFIED]: mapsIcons.ReverseCrossIcon,
  [NEW]: mapsIcons.ReverseCrossIcon,
  [NOT_EXIST]: mapsIcons.ReverseCrossIcon,
  [NOT_INSTALLED]: mapsIcons.ReverseCrossIcon,
  [INSTALLED]: mapsIcons.ReverseOkIcon,
  [UNINSTALLED]: mapsIcons.ReverseCrossIcon,
  null: mapsIcons.ReverseCrossIcon,
}

export const STATUS_TYPES = {
  [OK]: OK,
  [WARNING]: WARNING,
  [INFO]: INFO,
  [ERROR]: ERROR,
}

export const STATUS_TYPE_INSTALLATIONS = {
  [NOT_VERIFIED]: NOT_VERIFIED,
  [VERIFIED]: VERIFIED,
  [NEW]: NEW,
  [NOT_EXIST]: NOT_EXIST,
  [NOT_INSTALLED]: NOT_INSTALLED,
  [INSTALLED]: INSTALLED,
  [UNINSTALLED]: UNINSTALLED,
}

export const tooltipSizes = {
  lineHeight: 14,
  delta: 40,
  baseHeight: 24,
}

export const GEOZONE_NAME = 'Название геозоны'
export const CLUSTER_NAME = 'Кластер объектов'

export const PIE_CHART_SIZE = {
  small: {
    height: '38px', width: '38px',
  },
  medium: {
    height: '58px', width: '58px',
  },
  big: {
    height: '78px', width: '78px',
  },
}

export const PIE_CHART_POSITION = {
  position: 'absolute', top: '0px', left: '0px',
}

export const clusterToolTipLamdaBySize = {
  small: {
    container: '-85',
    tip: '11.5',
  },
  medium: {
    container: '-75',
    tip: '21.5',
  },
  big: {
    container: '-65',
    tip: '31.5',
  },
}

export const geoZoneToolTipLamdaBySize = {
  small: {
    container: '-105',
    tip: '11.5',
  },
  medium: {
    container: '-95',
    tip: '21.5',
  },
  big: {
    container: '-85',
    tip: '31.5',
  },
}

export const CHAR_PER_LINE_IN_TOOLTIP = 20
export const CHAR_PER_LINE_IN_TOOLTIP_CLUSTER_TOOLTIP = 18

const clusterPreset = [
  {
    href: transparentRectangleIcon,
    size: [40, 40],
    offset: [-20, -20],
  },
  {
    href: transparentRectangleIcon,
    size: [60, 60],
    offset: [-30, -30],
  },
  {
    href: transparentRectangleIcon,
    size: [80, 80],
    offset: [-40, -40],
  },
]

export const clusterCountBrakePoint = {
  small: 3,
  medium: 9,
  big: 14,
}

export const clusterOptions = {
  groupByCoordinates: false,
  clusterDisableClickZoom: true,
  clusterHideIconOnBalloonOpen: false,
  geoObjectHideIconOnBalloonOpen: false,
  clusterIcons: clusterPreset,
  clusterNumbers: [
    clusterCountBrakePoint.small,
    clusterCountBrakePoint.medium,
    clusterCountBrakePoint.big,
  ],
}

export const gridSize = {
  small: 16,
  middle: 128,
  big: 256,
  large: 512,
  huge: 1024,
}

export const mapZoomBreakPoints = {
  max: 19,
  min: 2,
  micro: 4,
  small: 6,
  middle: 8,
  upper: 10,
  big: 12,
  large: 15,
}

export const ZOOM_BY_TYPES = {
  [COUNTRY]: mapZoomBreakPoints.micro,
  [ROOT]: mapZoomBreakPoints.micro,
  [REGION]: mapZoomBreakPoints.middle,
  [CITY]: mapZoomBreakPoints.max - 9,
  [CONTROLLER]: mapZoomBreakPoints.max - 1,
  [LAMP]: mapZoomBreakPoints.max - 1,
  [PHASE]: mapZoomBreakPoints.max - 1,
  [BRANCH_CIRCUIT]: mapZoomBreakPoints.max - 1,
  [FUSE]: mapZoomBreakPoints.max - 1,
  [CURRENT_SENSOR]: mapZoomBreakPoints.max - 1,
  [PHASE_CIRCUIT_BREAKER]: mapZoomBreakPoints.max - 1,
  [CONTACTOR]: mapZoomBreakPoints.max - 1,
  [FIRE_SENSOR]: mapZoomBreakPoints.max - 1,
  [DMX_DEVICE]: mapZoomBreakPoints.max - 1,
  [LIGHT_FIXTURE]: mapZoomBreakPoints.max - 1,
  [BASE_STATION]: mapZoomBreakPoints.max - 1,
  [STREETLIGHT]: mapZoomBreakPoints.max - 1,
  [UTILITY_POLE]: mapZoomBreakPoints.max - 1,
  [STREETLIGHT_GROUP]: mapZoomBreakPoints.max - 1,
  [CENTRAL_HEATING_DEVICE]: mapZoomBreakPoints.max - 1,
  [STREETLIGHT_CONTROL_CABINET]: mapZoomBreakPoints.max - 1,
  [LIGHT_SENSOR]: mapZoomBreakPoints.max - 1,
  [LINE]: mapZoomBreakPoints.max - 1,
  [ELECTRIC_METER]: mapZoomBreakPoints.max - 1,
  [VOLUME_SENSOR]: mapZoomBreakPoints.max - 1,
  [DOOR_OPEN_SENSOR]: mapZoomBreakPoints.max - 1,
  [CONTROLLER_FUSE]: mapZoomBreakPoints.max - 1,
  [SMOKE_SENSOR]: mapZoomBreakPoints.max - 1,
  [CONTROL_CUPBOARD]: mapZoomBreakPoints.max - 1,
  [ROAD_TEMPERATURE_SENSOR]: mapZoomBreakPoints.max - 1,
  [MODULE]: mapZoomBreakPoints.max - 1,
  [TRANSFER_SWITCH]: mapZoomBreakPoints.max - 1,
  [PHOTO_SENSOR]: mapZoomBreakPoints.max - 1,
  [COLD_WATER_DEVICE]: mapZoomBreakPoints.max - 1,
  [MULTIRATE_METER]: mapZoomBreakPoints.max - 1,
  [WASTE_CONTAINER]: mapZoomBreakPoints.max - 1,
  [TRASH]: mapZoomBreakPoints.max - 1,
  [CHECKPOINT]: mapZoomBreakPoints.max - 1,
  [METEO]: mapZoomBreakPoints.max - 1,
  [LAMP_MODULE]: mapZoomBreakPoints.max - 1,
  [SWITCHING_GROUP]: mapZoomBreakPoints.max - 1,
  [GEOZONE]: mapZoomBreakPoints.max - 9,
  [PROJECT]: mapZoomBreakPoints.max - 9,
  [INTEGRATION]: mapZoomBreakPoints.max - 9,
  [GROUP]: mapZoomBreakPoints.max - 9,
}

export const icons = {
  geoIcon: mapsIcons.GeoIcon,
  listIcon: mapsIcons.ListIcon,
  filterIcon: mapsIcons.FilterIcon,
}

export const filterTypes = [
  {
    id: 1,
    type: OK,
    name: <Lang id="mapsPage.filterTypes.ok" />,
    inZone: false,
    isStatus: true,
  },
  {
    id: 2,
    type: WARNING,
    name: <Lang id="mapsPage.filterTypes.warning" />,
    inZone: false,
    isStatus: true,
  },
  {
    id: 3,
    type: INFO,
    name: <Lang id="mapsPage.filterTypes.info" />,
    inZone: false,
    isStatus: true,
  },
  {
    id: 4,
    type: ERROR,
    name: <Lang id="mapsPage.filterTypes.error" />,
    inZone: false,
    isStatus: true,
  },
  {
    id: 5,
    type: TRAFFIC,
    name: <Lang id="mapsPage.filterTypes.traffic" />,
    inZone: false,
    isStatus: false,
  },
  {
    id: 6,
    type: LIGHT,
    name: <Lang id="mapsPage.filterTypes.light" />,
    inZone: false,
    isStatus: false,
  },
  {
    id: 7,
    type: TRASH,
    name: <Lang id="mapsPage.filterTypes.trash" />,
    inZone: false,
    isStatus: false,
  },
  {
    id: 8,
    type: CONTROLLER,
    name: <Lang id="mapsPage.filterTypes.controler" />,
    inZone: false,
    isStatus: false,
  },
  {
    id: 9,
    type: GROUP_OBJECT,
    name: <Lang id="mapsPage.filterTypes.groupObject" />,
    inZone: false,
    isStatus: false,
  },
]

export const YMSP_Z_INDEX = {
  geoObject: {
    small: 1000,
    medium: 3000,
    big: 4000,
    large: 5000,
  },
}

export const TYPE_TO_NAME = {
  [BASE_STATION]: 'Базовая станция',
  [TRANSFER_SWITCH]: 'АВР',
  [GROUP]: 'Группа',
  [STREETLIGHT_GROUP]: 'Группа светильников',
  [SWITCHING_GROUP]: 'Группа ШУНО',
  [SMOKE_SENSOR]: 'Датчик задымленности',
  [VOLUME_SENSOR]: 'Датчик объема',
  [LIGHT_SENSOR]: 'Датчик освещенности',
  [DOOR_OPEN_SENSOR]: 'Датчик открытия двери',
  [FIRE_SENSOR]: 'Датчик пожара',
  [CURRENT_SENSOR]: 'Датчик тока',
  [ROAD_TEMPERATURE_SENSOR]: 'Датчик температуры дорожного полотна',
  [CONTACTOR]: 'Контактор',
  [CONTROLLER]: 'Контроллер',
  [CHECKPOINT]: 'Контрольная точка',
  [LAMP]: 'Лампа',
  [LINE]: 'Линия',
  [METEO]: 'Метеодатчик',
  [MULTIRATE_METER]: 'Многотарифный счетчик',
  [MODULE]: 'Модуль',
  [LAMP_MODULE]: 'Модуль светильника',
  [WASTE_CONTAINER]: 'Мусорный бак',
  [COMPANY_OBJECT]: 'Объект',
  [UTILITY_POLE]: 'Опора',
  [BRANCH_CIRCUIT]: 'Отходящая линия',
  [FUSE]: 'Предохранитель',
  [CONTROLLER_FUSE]: 'Предохранитель контроллера',
  [LIGHT_FIXTURE]: 'Светильник',
  [COLD_WATER_DEVICE]: 'Узел учета ХВС',
  [CENTRAL_HEATING_DEVICE]: 'Узел учета ЦО',
  [STREETLIGHT]: 'Уличный светильник',
  [DEVICE]: 'Устройство',
  [PHASE]: 'Фаза',
  [PHASE_CIRCUIT_BREAKER]: 'Фазовый выключатель',
  [PHOTO_SENSOR]: 'Фотодатчик',
  [STREETLIGHT_CONTROL_CABINET]: 'ШУНО',
  [CONTROL_CUPBOARD]: 'ШУНО',
  [ELECTRIC_METER]: 'Электрический счетчик',
  [DMX_DEVICE]: 'Устройство DMX',
  [SENSOR]: 'Датчик',
}

export const ASU_TYPE_TO_NAME = {
  [KULON]: 'АСУНО "КУЛОН"',
  [BRIZ]: 'АСУ "БРИЗ"',
  [ONE_SIM]: 'АСУ "1СИМ"',
  [MESH]: 'АСУ "МЕШ"',
  [WITHOUT_ASU]: 'Без АСУ',
  [UNILIGHT]: 'АСУ "ЮНИЛАЙТ"',
}

export const PROCESS_TO_NAME = {
  [OUTDOOR_LIGHTING]: 'Наружное освещение',
  [WATER_SUPPLY]: 'Водоснабжение',
  [HEAT_SUPPLY]: 'Теплоснабжение',
  [GARBAGE_COLLECTION]: 'Раздельный сбор мусора',
  [CHARGING_STATIONS]: 'Зарядные автостанции',
  [HEAT_AND_WATTER_SUPPLY]: 'Теплоснабжение и Водоснабжение'
}

export const DEFAULT_BORDER_RADIUS = 15000
export const UNITS_METERS = 100000

export const MAP_LAYERS = [
  {
    name: <Lang id="mapsPage.mapLayers.objects" />,
    id: OBJECTS,
    icon: mapsIcons.GeoZoneIcon
  },
  {
    name: <Lang id="mapsPage.mapLayers.lines" />,
    id: CONNECTED_LINES,
    icon: mapsIcons.InvertMarkIcon
  },
  {
    name: <Lang id="mapsPage.mapLayers.mesh" />,
    id: MESH,
    icon: mapsIcons.ClusterIcon
  },
  {
    name: <Lang id="mapsPage.mapLayers.russianBorders" />,
    id: RUSSIA_BORDERS,
    icon: null,
  },
]
