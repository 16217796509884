import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.NAME" />,
    showInDashboard: true,
    selector: 'NAME',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ROOT_OBJECT" />,
    showInDashboard: true,
    selector: 'ROOT_OBJECT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.IDENTIFIER" />,
    showInDashboard: true,
    selector: 'IDENTIFIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.EQUIPMENT_TYPE" />,
    showInDashboard: true,
    selector: 'EQUIPMENT_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL_MARK" />,
    showInDashboard: true,
    selector: 'MODEL_MARK',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PRODUCER" />,
    showInDashboard: true,
    selector: 'PRODUCER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.SUPPLIER" />,
    showInDashboard: true,
    selector: 'SUPPLIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.CONTROL_CUPBOARD_TYPE" />,
    showInDashboard: true,
    selector: 'CONTROL_CUPBOARD_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.CUPBOARD_SERIAL_NUMBER" />,
    showInDashboard: true,
    selector: 'CUPBOARD_SERIAL_NUMBER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.INCOMING_COUNTER_MODEL" />,
    showInDashboard: true,
    selector: 'INCOMING_COUNTER_MODEL',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.SERIAL_NUMBER_INCOMING_COUNTER" />,
    showInDashboard: true,
    selector: 'SERIAL_NUMBER_INCOMING_COUNTER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.OUTGOING_COUNTER_MODEL" />,
    showInDashboard: true,
    selector: 'OUTGOING_COUNTER_MODEL',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.SERIAL_NUMBER_OUTGOING_COUNTER" />,
    showInDashboard: true,
    selector: 'SERIAL_NUMBER_OUTGOING_COUNTER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
    showInDashboard: true,
    selector: 'REGISTRY_NUMBER',
    isCard: true
  },



  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE" />,
    showInDashboard: true,
    selector: 'WORKING_TEMPERATURE_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE" />,
    showInDashboard: true,
    selector: 'WORKING_VOLTAGE_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.ELECTRICITY_SUPPLIER" />,
    showInDashboard: true,
    selector: 'ELECTRICITY_SUPPLIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.RATED_POWER" />,
    showInDashboard: true,
    selector: 'RATED_POWER',
    isCard: true
  },



  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
    showInDashboard: true,
    selector: 'START_DATE',
    isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
      showInDashboard: true,
      selector: 'END_DATE',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
      showInDashboard: true,
      selector: 'ORGANIZATION',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
      showInDashboard: true,
      selector: 'OWNER',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
      showInDashboard: true,
      selector: 'GUARANTEE_PERIOD',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
      showInDashboard: true,
      selector: 'LIFE_TIME',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.EXPLOITATION.BALANCE_SHEET_HOLDER" />,
      showInDashboard: true,
      selector: 'BALANCE_SHEET_HOLDER',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.EXPLOITATION.PRODUCER_WARRANTY_EXPIRATION_DATE" />,
      showInDashboard: true,
      selector: 'PRODUCER_WARRANTY_EXPIRATION_DATE',
      isCard: true
  },



  {
    passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
    showInDashboard: true,
    selector: 'PROJECT',
    isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.LOCATION.LATITUDE" />,
      showInDashboard: true,
      selector: 'LATITUDE',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.LOCATION.LONGITUDE" />,
      showInDashboard: true,
      selector: 'LONGITUDE',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
      showInDashboard: true,
      selector: 'REGION',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
      showInDashboard: true,
      selector: 'CITY',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.LOCATION.STREET" />,
      showInDashboard: true,
      selector: 'STREET',
      isCard: true
  },
  {
      passportParameter: <Lang id="passport.fields.LOCATION.HOME_NUMBER" />,
      showInDashboard: true,
      selector: 'HOME_NUMBER',
      isCard: true
  },



  {
    passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.INSTALLATION_TYPE" />,
    showInDashboard: true,
    selector: 'INSTALLATION_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.LOCATION_TYPE" />,
    showInDashboard: true,
    selector: 'LOCATION_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.OUTGOING_LINES_NUMBER" />,
    showInDashboard: true,
    selector: 'OUTGOING_LINES_NUMBER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.CONNECTED_FIXTURES_NUMBER" />,
    showInDashboard: true,
    selector: 'CONNECTED_FIXTURES_NUMBER',
    isCard: true
  },


  {
    passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.PRESENCE_BASE_STATION" />,
    showInDashboard: true,
    selector: 'PRESENCE_BASE_STATION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.CONTROLLER_MODEL" />,
    showInDashboard: true,
    selector: 'CONTROLLER_MODEL',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.CONTROLLER_SERIAL_NUMBER" />,
    showInDashboard: true,
    selector: 'CONTROLLER_SERIAL_NUMBER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.SWVER" />,
    showInDashboard: true,
    selector: 'SWVER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.ICCID1" />,
    showInDashboard: true,
    selector: 'ICCID1',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.ICCID2" />,
    showInDashboard: true,
    selector: 'ICCID2',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.TIME_ZONE" />,
    showInDashboard: true,
    selector: 'TIME_ZONE',
    isCard: true
  },


  {
    passportParameter: <Lang id="passport.fields.SERVICING.PLAN_PPR_DATE" />,
    showInDashboard: true,
    selector: 'PLAN_PPR_DATE',
    isCard: true
},
{
    passportParameter: <Lang id="passport.fields.SERVICING.FACT_PPR_DATE" />,
    showInDashboard: true,
    selector: 'FACT_PPR_DATE',
    isCard: true
},
{
    passportParameter: <Lang id="passport.fields.SERVICING.MODERNIZATION" />,
    showInDashboard: true,
    selector: 'MODERNIZATION',
    isCard: true
},
]